import Alert, { AlertColor } from '@mui/material/Alert';
import { Theme } from '@mui/material/styles';
import { SnackbarContent, type SnackbarMessage, useSnackbar } from 'notistack';
import { ReactNode, useCallback, useMemo } from 'react';

import { ALERT_TYPES, DISABLE_CLICK_AWAY_CLASS } from '@/constants/global';
import { useWarningOrErrorEvent } from '@/hooks/analytics/useWarningOrErrorEvent';
import { capitalizeCase } from '@/utils/caseStyleTransforms';

const notificationStyles = {
  alert: ({ spacing }: Theme) => ({
    padding: `calc(${spacing('base')} - ${spacing('xxs')}) ${spacing('m')}`,
    borderRadius: spacing('base'),
    margin: 0,
  }),
};

type NotificationOptions = {
  message: string | SnackbarMessage;
  icon?: ReactNode;
  hideAfter?: number;
  autoClose?: boolean;
  preventDuplicate?: boolean;
  key?: string;
  type?: (typeof ALERT_TYPES)[number];
};

export type UseNotificationsReturn = {
  displaySuccess: (
    message: string | NotificationOptions,
    icon?: NotificationOptions['icon'],
    hideAfter?: NotificationOptions['hideAfter'],
    autoClose?: NotificationOptions['autoClose'],
    preventDuplicate?: NotificationOptions['preventDuplicate'],
    key?: NotificationOptions['key'],
    type?: NotificationOptions['type'],
  ) => void;
  displayWarning: (
    message: string | NotificationOptions,
    icon?: NotificationOptions['icon'],
    hideAfter?: NotificationOptions['hideAfter'],
    autoClose?: NotificationOptions['autoClose'],
    preventDuplicate?: NotificationOptions['preventDuplicate'],
    key?: NotificationOptions['key'],
    type?: NotificationOptions['type'],
  ) => void;
  displayError: (
    message: string | NotificationOptions,
    icon?: NotificationOptions['icon'],
    hideAfter?: NotificationOptions['hideAfter'],
    autoClose?: NotificationOptions['autoClose'],
    preventDuplicate?: NotificationOptions['preventDuplicate'],
    key?: NotificationOptions['key'],
    type?: NotificationOptions['type'],
  ) => void;
  displayInfo: (
    message: string | NotificationOptions,
    icon?: NotificationOptions['icon'],
    hideAfter?: NotificationOptions['hideAfter'],
    autoClose?: NotificationOptions['autoClose'],
    preventDuplicate?: NotificationOptions['preventDuplicate'],
    key?: NotificationOptions['key'],
    type?: NotificationOptions['type'],
  ) => void;
};

/**
 * Creates and outputs an object containing display functions for use in displaying a user a notification.
 */
export const useNotifications = (): UseNotificationsReturn => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const warningOrErrorEvent = useWarningOrErrorEvent();

  const enqueueSnackbarWithAnalytics = useCallback(
    ({ message, icon, hideAfter, type, autoClose = true, preventDuplicate = false, key = '' }: NotificationOptions) => {
      if ((type === 'error' || type === 'warning') && typeof message === 'string') {
        warningOrErrorEvent({ errorType: type, text: message });
      }

      enqueueSnackbar(message, {
        key,
        preventDuplicate,
        autoHideDuration: hideAfter || 6000,
        persist: !autoClose,
        content: (innerKey, innerMessage) => (
          <SnackbarContent className={DISABLE_CLICK_AWAY_CLASS} key={innerKey}>
            <Alert
              data-testid={`notification-${type}`}
              variant="filled"
              elevation={6}
              icon={icon}
              severity={type as AlertColor}
              onClose={() => closeSnackbar(innerKey)}
              sx={notificationStyles.alert}
            >
              {innerMessage}
            </Alert>
          </SnackbarContent>
        ),
      });
    },
    [warningOrErrorEvent, closeSnackbar, enqueueSnackbar],
  );

  const displayFunctions = useMemo(
    () =>
      ALERT_TYPES.reduce(
        (acc, type) => ({
          ...acc,
          [`display${capitalizeCase(type)}`]: (
            textOrOptions: string | Omit<NotificationOptions, 'type'>,
            icon: NotificationOptions['icon'],
            hideAfter: NotificationOptions['hideAfter'],
            autoClose: NotificationOptions['autoClose'],
            preventDuplicate: NotificationOptions['preventDuplicate'],
            key: NotificationOptions['key'],
          ) => {
            if (typeof textOrOptions === 'object' && 'message' in textOrOptions) {
              enqueueSnackbarWithAnalytics({ ...textOrOptions, type });
            } else {
              enqueueSnackbarWithAnalytics({
                message: textOrOptions,
                icon,
                hideAfter,
                type,
                autoClose,
                preventDuplicate,
                key,
              });
            }
          },
        }),
        {} as UseNotificationsReturn,
      ),
    [enqueueSnackbarWithAnalytics],
  );

  return { ...displayFunctions };
};
