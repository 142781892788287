const UNKNOWN_COUNTRY_CODE = 'ZZ';

// this file is imported by server.ts hence module.exports
export { UNKNOWN_COUNTRY_CODE };

export const QueryKeys = Object.freeze({
  currentUser: 'currentUser',
  keywordsSuggestions: 'keywordsSuggestions',
  locationSuggestions: 'locationSuggestions',
  locationDetails: 'locationDetails',
  userPermissions: 'userPermissions',
});

export const DEFAULT_LOCALE = 'en';
